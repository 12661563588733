import ClassificationServicesImpl from "./derechos_peticion_services_impl";

export default {
  getClassifition: async () => {
    return ClassificationServicesImpl.getClassifition();
  },
  getClassifitionBySearch: async (term) => {
    return ClassificationServicesImpl.getClassifitionBySearch(term);
  },
  // getReport: async (document_id) => {
  //   return AnnualReportServicesImpl.getReport(document_id);
  // },
  // manageReport: async (document_id, data) => {
  //   return AnnualReportServicesImpl.manageReport(document_id, data);
  // },
  // updateReport: async (document_id, data) => {
  //   return AnnualReportServicesImpl.updateReport(document_id, data);
  // },
};
