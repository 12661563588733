<template>
  <div class="w-full h-auto">
    <div v-if="loading" class="absolute w-full justify-center top-1/3 left-0">
      <a-spin>
        <a-icon slot="indicator" type="loading" style="font-size: 64px" spin />
      </a-spin>
    </div>
    <header
      class="mb-4 flex flex-col-reverse items-center justify-center lg:flex-row lg:justify-end gap-3"
    >
      <div class="w-full sm:max-w-sm">
        <label for="table-search" class="sr-only">Search</label>
        <div class="relative">
          <div
            class="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none"
          >
            <svg
              class="w-5 h-5 text-gray-500 dark:text-gray-400"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                clip-rule="evenodd"
              ></path>
            </svg>
          </div>
          <a-input-search
            placeholder="Buscar por nombre, entidad o código interno"
            class="rounded-full"
            style="width: 100%"
            v-model="filterSearch"
            @keyup.enter="handleSearch"
          />
        </div>
      </div>
    </header>

    <a-table
      class="table-component"
      :columns="columns"
      :data-source="data"
      :scroll="{ x: '1220px' }"
    >
      <span slot="nombre_cf" slot-scope="text, record">{{
        record.nombre_cf
      }}</span>
      <span slot="entidad" slot-scope="text, record">{{ record.entidad }}</span>
      <span slot="codigo_queja" slot-scope="text, record">
        {{ record.codigo_queja }}
      </span>
      <span slot="texto_queja" slot-scope="text, record">
        {{ `${record.texto_queja.substring(0, 30)}...` }}
      </span>
      <!-- @click="goToReportDetail(record.document_id)" -->
      <div
        class="flex justify-evenly align-center"
        slot="action"
        slot-scope="text, record"
        @click="goDetail(record.codigo_queja)"
      >
        <button class="text-sm text-blue h-min">
          <a-icon
            type="eye"
            theme="twoTone"
            two-tone-color="#3366cc"
            :style="{ fontSize: '22px' }"
          />
          &nbsp;
          <span>Ver</span>
        </button>
      </div>
    </a-table>
  </div>
</template>

<script>
import TableComponent from "@/components/UI/Table/TableComponent.vue";
import ClassificationServicesImpl from "@/feature/clasification/data/services/derechos_peticion_services";

const columns = [
  {
    slots: { title: "nombre_cf" },
    title: "Nombre del CF",
    dataIndex: "nombre_cf",
    key: "nombre_cf",
    scopedSlots: { customRender: "nombre_cf" },
  },
  {
    slots: { title: "entidad" },
    title: "Entidad",
    dataIndex: "entidad",
    key: "entidad",
    scopedSlots: { customRender: "entidad" },
  },
  {
    slots: { title: "codigo_queja" },
    title: "Código interno",
    dataIndex: "codigo_queja",
    key: "codigo_queja",
    scopedSlots: { customRender: "codigo_queja" },
  },
  {
    slots: { title: "texto_queja" },
    title: "Contexto",
    dataIndex: "texto_queja",
    key: "texto_queja",
    scopedSlots: { customRender: "texto_queja" },
  },
  {
    slots: { title: "action" },
    title: "Acciones",
    dataIndex: "action",
    key: "action",
    scopedSlots: { customRender: "action" },
  },
];

export default {
  components: {
    TableComponent,
  },
  data() {
    return {
      loading: false,
      count: 0,
      filterSearch: "",
      page: 1,
      pageSize: 100,
      pageSizeOptions: ["50", "100", "150", "200", "250"],
      columns: columns,
      data: [],
    };
  },
  async created() {
    this.getReports();
  },
  watch: {
    page: function (newVal, oldVal) {
      if (newVal == this.$route.query.page) {
        return;
      } else {
        this.updateRouterQuery();
      }
    },
    pageSize: function (newVal, oldVal) {
      if (newVal == this.$route.query.pageSize) {
        return;
      } else {
        this.$router.replace({
          query: { page: this.page, pageSize: this.pageSize },
        });
      }
    },
    "$route.query.page": function () {
      this.getData();
    },
    filterSearch: async function (term) {
      // console.log(term.length);
      if (term.length === 0) {
        this.loading = true;
        const response = await ClassificationServicesImpl.getClassifition();
        this.data = response.data.data;
        this.loading = false;
      }
    },
  },
  computed: {
    idCompany: function () {
      return this.$store.state.session?.user?.id_company?.id;
    },
  },
  methods: {
    async getReports() {
      this.loading = true;
      const response = await ClassificationServicesImpl.getClassifition();
      this.data = response.data.data;
      // console.log(this.data);
      this.loading = false;
    },
    goDetail(id) {
      // console.log(id);
      this.$router.push(`/dashboard/classification/right_request/detail/${id}`);
    },
    async handleSearch({ target }) {
      const { value } = target;
      // console.log(value);
      this.loading = true;
      const response = await ClassificationServicesImpl.getClassifitionBySearch(
        value
      );
      // console.log(response);
      this.data = response.data.data;
      this.loading = false;
    },
  },
};
</script>

<style scoped>
button {
  border-radius: 5px;
}

.flex-wrap {
  flex-wrap: wrap;
}

.p-10 {
  padding: 10px;
}

.d-flex {
  display: flex;
}

.flex-center {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-content: center;
  align-items: center;
}

.ms-auto {
  margin-left: auto;
}

.btn-large {
  min-width: 300px;
}

.btn-small {
  min-width: 90px;
  padding: 3px;
}

.btn-white {
  box-shadow: 0 0 2mm #e7e7e7;
}

.btn-square {
  width: 40px;
  height: 40px;
}

.btn-square-small {
  width: 26px;
  height: 26px;
}

.btn-gray {
  background: #e7e7e7;
  color: black;
}

.btn-primary {
  background: #004884;
  color: white;
}

.btn-warning {
  background: #f8b446;
  color: white;
}

.btn-danger {
  background: #ea4a76;
  color: white;
}

.btn-success {
  background: #309d7e;
  color: white;
}

.btn-warning-light {
  background: #fff3e0;
  color: #f8b446;
}

.btn-danger-light {
  background: #ffedf2;
  color: #ea4a76;
}

.btn-primary-light {
  background: #edf3ff;
  color: #004884;
}

.btn-success-light {
  background: #e5fbf5;
  color: #309d7e;
}

.no-border {
  border: 0px !important;
}

.border-bottom-gray {
  border-bottom: 1px solid #e7e7e7;
}

.flag {
  min-width: 90px;
  display: flex;
  padding: 5px;
  border-radius: 5px;
  flex-wrap: wrap;
  justify-content: center;
  align-content: center;
  align-items: center;
}

.search > input {
  border: 1px solid;
  border-radius: 10px;
  min-width: 400px;
  margin-right: 10px;
  padding: 0px 10px;
}

.search > button {
  border-radius: 10px;
}

.table {
  padding: 10px;
  border: 1px solid #e7e7e7;
  border-radius: 5px;
  flex-wrap: wrap;
}

.tableHeader {
  font-weight: bold;
}

.table > div {
  flex-wrap: wrap;
}

.table > div > div {
  border-bottom: 1px solid black;
  padding: 10px 1px;
}

.progressbar {
  height: 26px;
  border-radius: 5px;
  background: #e5fbf5;
  display: flex;
  justify-content: start;
  align-content: center;
  align-items: center;
}

.progressbar > div {
  height: 26px;
  color: white;
  border-radius: 5px;
  background: #309d7e;
  width: 50%;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
}

.w-100 {
  width: 100%;
}

.w-90 {
  width: 90%;
}

.w-80 {
  width: 80%;
}

.w-70 {
  width: 70%;
}

.w-60 {
  width: 60%;
}

.w-50 {
  width: 50%;
}

.w-45 {
  width: 45%;
}

.w-40 {
  width: 40%;
}

.w-35 {
  width: 35%;
}

.w-30 {
  width: 30%;
}

.w-25 {
  width: 25%;
}

.w-20 {
  width: 20%;
}

.w-15 {
  width: 15%;
}

.w-10 {
  width: 10%;
}

.w-5 {
  width: 5%;
}
</style>