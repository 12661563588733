//BASE APIS
const baseAnnualReportServi = "/annual-report-servi";
const classificationServi = "/jurisdiccional-servi";
//ANNUALREPORT APIS

const updateAnualReport = (id) =>
  `${baseAnnualReportServi}/update_report?id=${id}`;
const getCounters = (query) => `${baseAnnualReportServi}/get_counters${query}`;
const createAnualReport = `${baseAnnualReportServi}/create_report`;
const getAnualReport = (id) => `${baseAnnualReportServi}/get_report?id=${id}`;
const listAnualReports = (query) =>
  `${baseAnnualReportServi}/list_reports${query}`;
const updateAnualReportFiles = `/queries-servi/file/annual_report_attach`;

// Reportes
const getReports = () => `${baseAnnualReportServi}/list_ev_reports`;
const multifilterReport = (data) => `${baseAnnualReportServi}/list_ev_reports?responsable=${data.responsable}&state=${data.state}&filter_date=${data.filter_date}&entity_id=${data.entity_id}&start_date=${data.start_date}&end_date=${data.end_date}`;
const getReport = (id) => `${baseAnnualReportServi}/get_report_ev?id=${id}`;
const manageReport = (id) =>
  `${baseAnnualReportServi}/upload_ev_report?doc_id=${id}`;

const uploadFIlesReport = (id) =>
  `${baseAnnualReportServi}/upload_ev_attach?document_id=${id}`;

// Clasificación
const getClassifition = () => `${classificationServi}/right-request-classification`;
const getClassifitionBySearching = (term) => `${classificationServi}/right-request-classification?search=${term}`;

// Habeas data.
const getHabeasData = () => `${classificationServi}/habeas-data-classification`;
const getHabeasDataBySearching = (term) => `${classificationServi}/habeas-data-classification?search=${term}`;

export default {
  updateAnualReport,
  getCounters,
  createAnualReport,
  getAnualReport,
  listAnualReports,
  updateAnualReportFiles,
  getReports,
  getReport,
  multifilterReport,
  manageReport,
  getClassifition,
  getClassifitionBySearching,
  uploadFIlesReport,
  getHabeasData,
  getHabeasDataBySearching,
};
